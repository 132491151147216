import { Loader } from "@googlemaps/js-api-loader";
import { latLngToMiles, mapStyles } from "../utils";
const key = "AIzaSyCtL_2M4QkpYHDOKGuGwORyyJRRtf4VMSM";

const GMap = function () {
    // Location data
    this.locations = [];

    // Current map focal coords
    this.centerLatLng = { lat: null, lng: null };

    // Google map markers
    this.mapMarkers = [];

    // Selected via filtering / distance etc/
    this.filteredByTerm = [];
    this.filteredByTermAndDistance = [];

    // Google Map JS API
    this.googleMap = null;

    // Results elements wrapper (slide in)
    this.resultsWrapper = null;

    // Results elements container (holding results els list)
    this.resultsContainer = null;

    // Result elements
    this.results = [];

    const self = this;

    this.init = function () {
        self.mapMarkers.forEach(function (marker) {
            // Zoom to location and order details cards based on distance & expand selected
            marker.addListener("click", function () {
                const markerPos = marker.getPosition();
                self.googleMap.setCenter(markerPos);
                self.centerLatLng = {
                    lat: markerPos.lat(),
                    lng: markerPos.lng(),
                };
                self.orderResultsByDistance(self.centerLatLng);
                self.googleMap.setZoom(16);

                self.resultsWrapper.classList.add("active");

                self.toggleResult(marker.id);
            });
        });
        self.locations.forEach(function (location) {
            const resultEl = self.createResultElement(location);
            self.results.push(resultEl);
            self.resultsContainer.appendChild(resultEl);
        });
    };

    this.showResults = function () {
        self.orderResultsByDistance(self.centerLatLng);
        self.resultsWrapper.classList.add("active");
    };

    this.hideResults = function () {
        self.resultsWrapper.classList.remove("active");
    };

    // Create result element from pin, add GMap centering on click
    this.createResultElement = function (location) {
        const result = document.createElement("button");
        result.type = "button";
        result.classList.add("js-mapResult", "map-result");

        // Data attributes for ID
        result.dataset.id = location.id;

        const gmapPin = self.mapMarkers.find(function (m) {
            return `${m.id}` === `${location.id}`;
        });

        const directionsURL = `https://www.google.com/maps/dir/?api=1&destination=${location.latLng.lat},${location.latLng.lng}`;

        let detailsHtml = '';

        if (location.email && location.email !== '') {
            detailsHtml += `<a href="mailto:${location.email}" class="map-result__detail map-result__detail--email" >${location.email}</a>`;
        }

        if (location.telephone && location.telephone !== '') {
            detailsHtml += `<a href="tel:${location.telephone}" class="map-result__detail map-result__detail--phone">${location.telephone}</a>`;
        }

        if (location.website && location.website !== '') {
            detailsHtml += `<a href="${location.website}" target="_blank" class="map-result__detail map-result__detail--website">${location.website}</a>`;
        }

        detailsHtml += `<a href="${directionsURL}" target="_blank" class="map-result__detail map-result__detail--directions">Download directions</a>`;

        result.innerHTML = `
        <span class="map-result__name">${location.title}</span>
              <span class="map-result__detail map-result__detail--${location.icon}">${location.address}</span>
              <div class="map-result__details">${detailsHtml}</div>
              <button
                type="button"
                class="js-mapResultExpand map-result__see-more"
              >See more details</button>
              <button
                type="button"
                class="js-mapResultHide map-result__hide-more"
              >Hide details</button>
        `;

        // Go to map pin & show details
        result.addEventListener("click", function (e) {
            const resultPosition = gmapPin.getPosition();
            self.googleMap.setCenter(resultPosition);

            self.centerLatLng = {
                lat: resultPosition.lat(),
                lng: resultPosition.lng(),
            };

            // Always force scroll back to the top
            self.resultsWrapper.scrollTop = 0;

            self.googleMap.setZoom(16);
            self.orderResultsByDistance(self.centerLatLng);
            // To hide details when hide btn is clicked
            if (!e.target.classList.contains("js-mapResultHide")) {
                self.toggleResult(location.id);
            }
        });

        // Show/hide details
        const expandBtn = result.querySelector(".js-mapResultExpand");
        const collapseBtn = result.querySelector(".js-mapResultHide");

        expandBtn.addEventListener("click", function () {
            result.classList.add("expanded");
        });
        collapseBtn.addEventListener("click", function () {
            result.classList.remove("expanded");
        });

        return result;
    };

    this.orderResultsByDistance = function (centerLatLng) {
        // Order results based on distance from selected center
        self.results = self.results.concat().sort(function (a, b) {
            // Bit of trig to determine absolute distance
            const aId = a.dataset.id;
            const locationA = self.locations.find(function (l) {
                return `${l.id}` === `${aId}`;
            });
            const dxA = locationA.latLng.lat - centerLatLng.lat;
            const dyA = locationA.latLng.lng - centerLatLng.lng;
            const distanceA = Math.sqrt(dxA ** 2 + dyA ** 2);

            const bId = b.dataset.id;
            const locationB = self.locations.find(function (l) {
                return `${l.id}` === `${bId}`;
            });
            const dxB = locationB.latLng.lat - centerLatLng.lat;
            const dyB = locationB.latLng.lng - centerLatLng.lng;
            const distanceB = Math.sqrt(dxB ** 2 + dyB ** 2);

            return distanceA < distanceB ? -1 : 1;
        });

        self.results.forEach(function (element, idx) {
            element.style.order = idx;
        });
    };

    this.toggleResult = function (id) {
        self.results.forEach(function (res) {
            if (parseInt(res.dataset.id, 10) === id) {
                res.classList.add("expanded");
            } else {
                res.classList.remove("expanded");
            }
        });
    };

    this.filterMap = function (searchTerm, productType, capDistance) {
        self.filteredByTerm = self.locations.filter(function (l) {
            return l.type.toLowerCase().includes(searchTerm.toLowerCase());
        });
        self.filterByTermAndDistance(
            self.centerLatLng.lat,
            self.centerLatLng.lng,
            capDistance,
            productType
        );
    };

    this.filterByTermAndDistance = function (centerLat, centerLng, capDistance, productType) {
        self.filteredByTermAndDistance = self.filteredByTerm
            .filter(function (location) {
                const { lat, lng } = location.latLng;
                const distance = latLngToMiles(lat, lng, centerLat, centerLng);
                const isWithinDistance = distance <= (capDistance ? 40 : 99999);
                let isSelectedProductType = true;
                if (location.type !== 'division') {
                    if (productType) {
                        switch (productType) {
                            case "plaspave":
                            case "buildingblocks":
                                isSelectedProductType = location.icon.toLowerCase().includes(productType) || location.icon.toLowerCase().includes("hybrid");
                                break;
                            case "hybrid":
                            default:
                                isSelectedProductType = true;
                                break;
                        }
                    }
                }
                return isWithinDistance && isSelectedProductType;
            })
            .map(function (l) {
                return l.id;
            });
        self.updateVisibleResults();
    };

    this.updateVisibleResults = function () {
        self.results.forEach(function (res) {
            const resId = parseInt(res.dataset.id, 10);
            if (!self.filteredByTermAndDistance.includes(resId)) {
                res.classList.add("hidden");
            } else {
                res.classList.remove("hidden");
            }
        });

        this.updateVisibleMarkers();
    };
    this.updateVisibleMarkers = function () {
        self.mapMarkers.forEach(function (marker) {
            const markerId = parseInt(marker.id, 10);
            if (!self.filteredByTermAndDistance.includes(markerId)) {
                marker.setVisible(false);
            } else {
                marker.setVisible(true);
            }
        });
    };
};

(function () {
    const mapWidgets = document.querySelectorAll(".js-stockistMaps");

    const loader = new Loader({
        apiKey: key,
        version: "weekly",
        libraries: ["places"],
    });

    const mapOptions = {
        zoom: 7,
        disableDefaultUI: true,
        styles: mapStyles,
    };

    setupMaps(mapWidgets, loader, mapOptions);
})();

function setupMaps(mapWidgets, loader, mapOptions) {
    mapWidgets.forEach(async function (widget) {
        const map = widget.querySelector(".js-googleMaps");
        if (!map || !widget.dataset.url) return;

        // Fetch data from endpoint
        // const locations = await fetch(widget.dataset.url).then((res) => res.json());
        const locationBodyData = {
            HideStockists: false,
            HideDivisions: false
        };

        const locations = await fetch(widget.dataset.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(locationBodyData),
        }).then((res) => res.json());

        // Setup pin ID & pin image
        const pins = locations.map(function (p, idx) {
            locations[idx].id = idx;
            let icon = "";
            switch (p.icon) {
                case "plaspave":
                    icon = "icons/map-pin--stockist.png";
                    break;
                case "buildingblocks":
                    icon = "icons/map-pin--contractor.png";
                    break;
                case "hybrid":
                    icon = "icons/map-pin--division.png";
                    break;
                case "division":
                    icon = "icons/map-pin.svg";
                    break;

                default:
                    icon = "icons/map-pin--orange.svg";
                    break;
            }
            return {
                ...p,
                id: idx,
                icon,
            };
        });

        // Center map around first pin
        const mapOpts = {
            ...mapOptions,
            center: {
                lat: parseFloat(pins[0].latLng.lat),
                lng: parseFloat(pins[0].latLng.lng),
            },
        };
        const searchField = widget.querySelector(".js-searchField");
        const filterField = widget.querySelector(".js-filterField");
        const productTypeField = widget.querySelector(".js-productTypeField");

        const searchBtn = widget.querySelector(".js-searchMap");

        const resultsWrapper = widget.querySelector(".js-mapResults");
        const resultsContainer = widget.querySelector(".js-mapResultsContainer");
        const hideResultsBtn = widget.querySelector(".js-resultsClose");

        let markers = [];

        let filterTerm = filterField.value;
        let productType = productTypeField.value;

        loader
            .load()
            .then((google) => {
                const myGMap = new GMap();
                myGMap.googleMap = new google.maps.Map(map, mapOpts);
                myGMap.locations = locations;
                myGMap.resultsWrapper = resultsWrapper;
                myGMap.resultsContainer = resultsContainer;

                const zoomIn = map.parentElement.querySelector(".js-zoomIn");
                const zoomOut = map.parentElement.querySelector(".js-zoomOut");

                productTypeField.classList.add("disabled");

                // Add markers to map from data
                pins.forEach(function (p) {
                    const pin = new google.maps.Marker({
                        position: {
                            lat: parseFloat(p.latLng.lat),
                            lng: parseFloat(p.latLng.lng),
                        },
                        map: myGMap.googleMap,
                        title: p.title,
                        animation: google.maps.Animation.DROP,
                        icon: {
                            url: "/sitefiles/dist/images/" + p.icon,
                            scaledSize: new google.maps.Size(41, 56),
                        },
                    });

                    pin.id = p.id;

                    myGMap.mapMarkers.push(pin);
                });

                // console.log(myGMap.mapMarkers);

                myGMap.init();

                myGMap.centerLatLng = {
                    lat: parseFloat(pins[0].latLng.lat),
                    lng: parseFloat(pins[0].latLng.lng),
                };

                // Map controls
                zoomIn.addEventListener("click", function () {
                    myGMap.googleMap.setZoom(myGMap.googleMap.getZoom() + 1);
                });

                zoomOut.addEventListener("click", function () {
                    myGMap.googleMap.setZoom(myGMap.googleMap.getZoom() - 1);
                });

                if (searchField) {
                    const searchBox = new google.maps.places.SearchBox(searchField);
                    // Zoom to location from search box & order results based on distance
                    searchBtn.addEventListener("click", function () {
                        const places = searchBox.getPlaces();
                        if (places && places.length) {
                            const lat = places[0].geometry.location.lat();
                            const lng = places[0].geometry.location.lng();

                            myGMap.googleMap.setCenter({ lat, lng });
                            myGMap.centerLatLng = { lat, lng };
                            myGMap.googleMap.setZoom(10);
                        }
                        myGMap.filterMap(filterTerm, '', true);

                        myGMap.showResults();

                        var results = resultsWrapper.querySelectorAll(".js-mapResult:not(.hidden)");
                        if (results.length > 0) {
                            resultsWrapper.querySelector(".js-no-results-message").classList.add("hidden");
                        } else {
                            NoResultMessage(filterTerm, resultsWrapper);
                        }
                        if (filterTerm === 'division' || filterTerm === 'contractor') {
                            var select = productTypeField.querySelector(".js-selectNative");
                            var customSelect = productTypeField.querySelector(".select-custom-trigger");

                            select.selectedIndex = 0;
                            customSelect.dataset.value = "";
                            customSelect.innerHTML = select.options[select.selectedIndex].text;
                            productTypeField.classList.add("disabled");
                        } else {
                            productTypeField.classList.remove("disabled");
                        }
                    });
                } else {
                    productTypeField.classList.add("disabled");
                }

                if (hideResultsBtn) {
                    hideResultsBtn.addEventListener("click", function () {
                        myGMap.hideResults();
                    });
                } else {
                    // Show results if no search/filter e.g. office locations
                    myGMap.showResults();
                }

                if (filterField) {
                    // Update markers based on filter selected
                    filterField.addEventListener("change", function (e) {
                        filterTerm = e.target.value;
                    });
                }

                if (productTypeField) {
                    // Update markers based on filter selected
                    productTypeField.addEventListener("change", function (e) {
                        productType = e.value;
                        myGMap.filterMap(filterTerm, productType, true);
                        myGMap.showResults();
                        var results = resultsWrapper.querySelectorAll(".js-mapResult:not(.hidden)");
                        if (results.length > 0) {
                            resultsWrapper.querySelector(".js-no-results-message").classList.add("hidden");
                        } else {
                            NoResultMessage(filterTerm, resultsWrapper, productType);
                        }
                    });
                }

                // Default filter to division
                myGMap.filterMap("division", productType);
            })
            .catch((e) => {
                console.log(e);
            });
    });
}

function NoResultMessage(filterTerm, resultsWrapper, productField) {
    let message = "No";
    if (filterTerm === 'division') {
        message += " Divisional offices";
    } else if (filterTerm === 'contractor') {
        message += " Contractors";
    } else if (filterTerm === 'stockist') {
        if (productField != null) {
            if (productField === 'plaspave') {
                message += " Paving";
            } else if (productField === 'buildingblocks') {
                message += " Block";
            }
        }
        message += " Stockists";
    }

    message += " nearby, please search a different area";
    let resultsMessage = resultsWrapper.querySelector(".js-no-results-message");
    resultsMessage.classList.remove("hidden");
    resultsMessage.innerHTML = message;
}
